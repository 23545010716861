.service-tab-wrapper {
    background-color: #052d83;
    padding: 20px 0px 20px 15px !important;
    border-radius: .75rem 0rem 0rem .75rem !important;
    z-index: 10;
    position: relative;
    height: 100%;
    flex-direction: column;
  }
  .service-tab-wrapper a.nav-link,
  .service-tab-wrapper a.nav-link:hover{
      color: #fff !important;
      cursor: pointer;
      border-radius: 50px 0px 0px 50px !important;
      margin-bottom: 8px;
  }
  div#service-tab a.nav-link.active {
      color: #fff !important;
      font-weight: 700;
      background-color: #4b66a5;
  }
  .services-tab-details{
      background: #fff;
      padding: 50px;
      box-shadow: rgb(99 99 99 / 10%) 0px 2px 8px 0px;
      border-radius: 0rem 0.75rem 0.75rem 0rem;
      height: 100%;
  }
  .service-tab-images{
      flex-wrap: wrap;
      column-gap: .75rem;
  }
  .service-tab-images img{
      width: 290px !important;
      margin-bottom: 1rem;
  }
  @media (max-width: 991px) {
      .service-tab-wrapper {
          flex-wrap: wrap;
          padding: 10px;
          border-radius: .75rem .75rem 0rem 0rem !important;
          flex-direction: row !important;
      }
      .service-tab-wrapper a.nav-link.active {
          border-radius: 50px !important;
      }
      .services-tab-details{
          border-radius: 0rem 0rem .75rem .75rem !important;
          padding: 30px;
      }
  }

  @media (max-width: 575px) {
    .services-tab-details{
        padding: 20px;
    }
}