@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
body {
    font-family: 'Mulish', sans-serif !important;
  }



  h1, h2, h3,h4, h5, h6 {
    font-family: "Mulish", sans-serif !important;
    color: #101010;
    margin-top: 0px !important;
    line-height: 1.3 !important;
    margin-bottom: 0 !important;
    font-weight: 800 !important;
}

h1 {
    font-size: 60px;
}
h2 {
    font-size: 45px !important;
}


h3 {
    font-size: 36px !important;
}
h5{
    font-size: 22px !important;
}

h6 {
    font-size: 18px;
}


p {
    color: #101010 ;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.5;
}

.section-heading{
    font-size: 32px !important;
}

.text-red {
    color: #cc0017;
}
/* CTA button */
.cust-padding {
    padding: 70px 0px;
}

.z-11{
    z-index: 11 !important;
}

/* ----header---------- */
#header-sticky .primary-btn-1.btn-hover{
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #101010;
}

#header-sticky .primary-btn-1:hover {
    background-color: #cc0017 !important;
    border-color: #cc0017 !important;
    color: #fff;
}


.primary-btn-1 {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    background-color: #052d83;
    border: 1px solid #052d83;
    padding: 14px 24px;
    border-radius:8px;
    display: inline-flex;
    text-transform: none;
    white-space: normal;
    color: #FFF;
    transition: all .6s;
    text-decoration: none;
    line-height: 1;
}

.primary-btn-1:hover {
    background-color: #cc0017 !important;
    border-color: #cc0017 !important;
}

.primary-btn {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    background-color: #052d83;
    border: 1px solid #052d83;
    padding: 14px 24px;
    border-radius:8px;
    display: inline-flex;
    text-transform: none;
    white-space: normal;
    color: #FFF;
    transition: all .6s;
    text-decoration: none;
    line-height: 1;
}

.primary-btn:hover {
    background-color: #cc0017 !important;
    border-color: #cc0017 !important;
}



.header-logo img{
    width: 70px;
}

.header-area {
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 111;
}


.header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-right {
    gap: 40px;
}

.main-menu ul{
    margin-bottom: 0;
}
.main-menu ul li {
    /* position: relative; */
    list-style: none;
    display: inline-block;
    margin-inline-end: 30px;
}

.main-menu ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #151515;
    padding: 35px 0;
    text-align: left;
    position: relative;
    text-decoration: none;
}

.main-menu ul li:hover > a {
    color: #fff;
}
.main-menu ul li > a {
    color: #e3e3e3;
}

#header-sticky-active .main-menu ul li > a {
    color: #101010;
}

.mean__menu-wrapper ul li.products-nav-list .dropdown-menu {
    transition: all 0.3s ease-in-out;
}
.mean__menu-wrapper ul li.products-nav-list:hover .dropdown-menu{
    display: block; 
    padding: 0;
}

.mean__menu-wrapper ul li.service-nav-list .dropdown-menu {
    transition: all 0.3s ease-in-out;
}
.mean__menu-wrapper ul li.service-nav-list:hover .dropdown-menu{
    display: block; 
    padding: 0;
}

.dark-logo{
    display: none;
}

#header-sticky-active .dark-logo{
    display: block;
}

#header-sticky-active .light-logo{
    display: none;
}

.offcanvas-header  .light-logo{
    display: block !important;
}

#header-sticky {
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
  }
#header-sticky-active{
    background-color: #fff !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.bg-blue{
    background-color: #052d83;
}
.dropdown-menu{
    width: 100%;
    position: absolute;
    left: 0;
    border: none !important;
    z-index: 99;
}

/* .mm-menu-wrapper */

.mm-menu-wrapper .mega-menu{
   width: 100%;
   background-image: linear-gradient(93deg, #052D83 45%, #f4f4f4 73%);
}

.menu-state_left {
    border-right: 5px solid #5d77b3;
    height: 100%;
    padding: 30px;
    padding-left: 0;
    position: relative;
}

.small-head {
    font-size: 14px;
    color: #f1f1f1;
}

.menu-state_left p {
    color: #fff;
    font-weight: 500;
}

.bg-white{
    background-color: #fff;
}

.bg-gray{
    background-color: #f4f4f4;
}
.menu-state_left .hr {
    height: 1px;
    background-color: #ffffff47;
    margin-top: 8px;

}

 .hr-2 {
    height: 1px;
    background-color: #1616161a;
    margin-top: 8px;

}


.mega-menu ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #151515;
    padding: 35px 0;
    text-align: left;
    position: relative;
    text-decoration: none;
}


.view-btn {
    color: #fff !important;
    font-weight: 700 !important;
}
.mt-40{
    margin-top: 40px;
}

.header-area .outlined {
    color: #fff;
    border-color: #4e68a4 !important;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
}

.color-row-menu {
    padding: 30px;
    padding-right: 0;
}

.color-row-menu h6 {
    font-size: 16px !important;
    color: #052d83;
}

.main-menu ul li .mega-menu li a{
    padding-inline-start: 0;
    color: #101010;
    padding: 10px 0 0 0;
    text-decoration: none;
    font-weight: 500;
}
.main-menu ul li .mega-menu li a:hover {
    padding-inline-start: 0;
    color: #052d83;
    text-decoration: underline;
}
.mmenu-ul{
    padding-left: 0;
    display: inline-grid;
}

.offcanvas-body .main-menu li:hover > a{
color:#000000 !important
}

.z-4{
    z-index: 1111 !important;
}


.socials-bucket {
    position: fixed;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
}

.social-btn.bt-facebook {
    background-color: #4267B2;
}
.social-btn.bt-instagram {
    background: linear-gradient(50deg, rgb(254, 218, 117) 5%, rgb(214, 41, 118) 50%, rgb(150, 47, 191) 100%);
}
.social-btn.bt-twitter {
    background-color: #000000;
}
.social-btn.bt-linkedin {
    background-color: #0077b5;
}
.social-btn.bt-youtube {
    background-color: #FF0000;
}

a.social-btn {
    width: 45px;
    height: 45px;
    color: #fff;
    display: block;
    align-content: center;
    text-align: center;
    border-radius: 0 30px 30px 0;
    font-size: 18px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.3s 0s ease-out;
}

a.social-btn:hover {
    scale: 1.15;
}

.custom-cta_common {
    background: #052d83 !important;
    color: #fff !important;
    font-weight: 600 !important;
    padding: 10px 24px !important;
    font-size: 18px !important;
    border: 0;
    border-radius:8px 8px 0 0 !important;
    transform: rotate(-90deg);
    
} 
.common-cta_bucket {
    position: fixed;
    bottom: 50%;
    right: -55px;
    z-index: 1111;
}


/* ---hero section---- */

.main-btn {
    padding: 12px 24px;
    background-color: transparent;
    border: 2px solid #fff;
    font-size: 18px;
    display: inline-block;
    margin-top: 30px;
    font-weight: 600;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    
}

.main-btn:hover {
    background-color: #052d83;
    border-color: #052d83;
}

.cust-padding2 {
    padding: 70px 0px 0px;
}
.cust-padding3 {
    padding: 170px 0px 70px;
}
.bg-purp {
    background-color: #ededff;
}

/* ----- servcie section---- */

.service-tab-title-area .primary-btn-4 {
    text-transform: uppercase;
    border: 1px solid #fff;
    background: transparent;
    font-weight: 600;
}

.service-tab-title-area .primary-btn-4:hover{

    background-color: #cc0017 !important;
    border-color:#fff !important ;
}

.ouline-btn-white {
    text-transform: capitalize;
    border: 2px solid #fff !important;
    background-color: transparent !important;
    border-radius: 8px;
    font-weight: 700 !important;
    font-size: 18px !important;
    padding: 12px 24px;
    text-decoration: none;
}
.primary-btn-4 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    background-color: #101010;
    border: 1px solid #101010;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    white-space: nowrap;
    padding: 12px 20px;
    gap: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all .6s;
}
.primary-btn-4:hover{
    background-color:#cc0017 !important;
}

.light-bg {
    background-color: transparent;
    color: #052d83;
    font-size: 20px;
    font-weight: 700;
    border: 1px solid #052d83 !important;
    border-radius:8px;
    text-decoration: none;
}
.light-bg:hover{
    color:#fff;
    border-color: #cc0017 !important;
}
.service-tab-content ul.tab-list-content li:before {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    content: "";
    vertical-align: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23cc0017' viewBox='0 0 16 16'%3E%3Cpath d='M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0'/%3E%3Cpath d='M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z'/%3E%3C/svg%3E");
     background-repeat: no-repeat;
    background-size: 22px 22px;
    width: 20px;
    height: 20px;
    color:#cc0017;
}


.spl-feat-list li{
    position: relative;
    padding-bottom: 14px;
}
.spl-feat-list {
    margin-left: 30px;
    padding: 0;
}
.spl-feat-list li:before {
    position: absolute;
    left: -30px;
    top: 5px;
    display: inline-block;
    content: "";
    vertical-align: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23cc0017' viewBox='0 0 16 16'%3E%3Cpath d='M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0'/%3E%3Cpath d='M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z'/%3E%3C/svg%3E");
     background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    color:#cc0017;
}


.prods-cards {
    text-align: center !important;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 100%;
    display: block;
    text-decoration: none;
}
.prods-img img {
    margin-bottom: 10px;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    object-fit: cover;
}
.prods-cards h6 {
    font-size: 16px;
    font-weight: 600 !important;
    color: #101010;
   
}
.serv-prod-head {
    margin-bottom: 50px !important;
}

.my-hr {
    margin: 70px 0px 50px !important;
    border-color: rgb(22 22 22 / 54%) !important;
}
/* ---margin-padding---- */

.mb-20 {
    margin-bottom: 20px !important;
}
ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.pt-10{
    padding-top: 10px;
} 
.pb-10{
    padding-bottom: 10px;
}

.abt-cta-inner h4 {
    font-size: 42px;
    color: #fff;
    font-weight: 400 !important;
}

.bg-grey {
    background-color: #f4f5f8;
}


table td {
    font-size: 18px;
    padding: 8px !important;
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive table{
    border: none;
}
.table-responsive tr{
    border-color:#dee2e6 ;
}

.table-responsive th{
    width: 50%;
}

.offcanvas-open {
    overflow: hidden;
}
.outlined {
    background-color: transparent;
    color: #052d83;
    border-color: #052d83 !important;
}
.primary-btn-1.outlined:hover {
    background-color: #cc0017 !important;
    border-color: #cc0017 !important;
    color:#fff;
}

.product-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a8;
}

.inner-page-padding{
    padding: 190px 0 100px;
}
.product-description {
    font-size: 20px;
    margin-top: 10px;
}

.product-title, .product-description {
    color: #fff;
}

.bg-less-btn {
    font-size: 18px !important;
    text-decoration: underline;
    font-weight: 700;
    text-underline-position: under;
    opacity: .8;
    color: #052d83;
    transition: all 0.3s 0s ease-out;
    cursor: pointer;
}
a.bg-less-btn:hover {
    font-size: 20px !important;
}
.section-paragraph {
    font-size: 18px;
    line-height: 1.4;
}

.ip-padding{
    padding: 100px 0;
}

/* -----inner pages----- */

.section-sub-title {
    position: relative;
    color:#cc0017;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase;
    margin-left: 0px;
}

.section-sub_heading {
    font-size: 20px;
}

.cta-cover {
    padding: 40px;
    border-radius: 8px;
    background-color: #052d83;
}
.btn-light {
    font-weight: 600 !important;
    font-size: 18px !important;
    text-transform: capitalize !important;
    background-color: #ffffff !important;
    border: 1px solid #052d83 !important;
    padding: 12px 24px !important;
    border-radius: 8px !important;
    text-transform: none !important;
}
.btn-light:hover{
    color: #101010 !important;
    background-color: #d3d4d5 !important;
    border-color:#d3d4d5 !important;
}
.text-primary-red{
    color: #cc0018;
}

.icon-list li:not(:last-child) {
    margin-bottom: 20px;
}

.icon-list li {
    font-family: "Mulish", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 10px;
    align-items: baseline;
}
.icon-list.style-two li i {
    flex-shrink: 0;
    color: #0a2e80;
    font-size: 20px;
    position: relative;
    top: 2px;
}

.q-links-list_wrapper a {
    padding: 5px 15px !important;
    background: #f0f0f0;
    border: 1px solid #ddd;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 20px;
    transition: all .3s ease;
    cursor: pointer;
    color: #101010;
    text-decoration: none;
    font-size: 16px !important;
}

.q-links-btn a {
    border: 1px solid #0e2e7e !important;
    background-color: unset !important;
    color: #0e2e7e !important;
    border-radius:8px !important;
    margin: 0 !important;
}
.q-links-btn {
    display: flex;
    gap: 10px;
}

.q-links-list_wrapper a:hover {
    background: #eceef4 !important;
    border-color: #c6c7c8 !important;
    
}

.product-specification .accordion-button:not(.collapsed){
    background-color: transparent;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.product-specification .accordion-button:not(.collapsed){
    color: #cc0017 !important;
}
.product-specification .accordion-button{
    padding: 20px 30px;
    color: #101010 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
}
.product-specification .accordion-body{
    padding: 20px 30px;
    border-top: 1px solid #ddd;
    line-height: 28px;
}
.product-specification .accordion-item{
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border-radius: 8px !important;
}

.product-specification .accordion-button::after{
    background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2216%22 height%3D%2216%22 fill%3D%22currentColor%22 viewBox%3D%220 0 16 16%22%3E%3Cpath fill-rule%3D%22evenodd%22 d%3D%22M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708%22/%3E%3Cpath fill-rule%3D%22evenodd%22 d%3D%22M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708%22/%3E%3C/svg%3E');
    transform: none;

}

.product-specification .accordion-button:not(.collapsed)::after{
    background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2216%22 height%3D%2216%22 fill%3D%22%23cc0017%22 viewBox%3D%220 0 16 16%22%3E%3Cpath fill-rule%3D%22evenodd%22 d%3D%22M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708z%22/%3E%3Cpath fill-rule%3D%22evenodd%22 d%3D%22M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708z%22/%3E%3C/svg%3E');
    transform: none;

}
.bn-content-bucket {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: unset;
    padding: 5px;
}

.banner-gallery img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    object-fit: unset !important;
    border-radius: 8px !important;
}


/* scroll-sticky--- */

.right {
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
  }
  .right figure.image {
    padding: 10px;
    background-color: #fff;
    border-radius: 0.75rem;
    border: 1px solid #e4e4e4;
    width: fit-content;
    float: right;
  }
  
  .scroll-icon {
    width: 80px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex-shrink: 0;
  }
  .scroll-text{
      font-size: 40px;
      line-height: 1;
      text-align: center;
      color: #cc0017;
      line-height: 40px !important;
  }

  .form-control:focus{
    box-shadow: none !important;
    border-color: #dee2e6 !important;
  }
  .modal-header .btn-close:focus{
    box-shadow: none !important;
  }

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#8d8c8c;
}

::-webkit-scrollbar-track {
    background: #e2e2e2;
}

/*  get in touch form */

.contact-form label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1px;
}

#contactform label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1px;
}

#contactform .error-msg {
    position: absolute;
    font-size: 14px;
    line-height: 1;
  }
.form-select:focus {
    box-shadow: none !important;
}

.contact-form .form-control:focus, .contact-form select:focus, .contact-form textarea:focus{
    border-color: #052d83 !important;
}

.project-slider-two-box figure{
    height: 150px;
}

.mbl-sub-list i{
    font-size: 18px;
}
.rotate-icon {
    transform: rotate(135deg);
    transition: transform 0.3s ease-in-out;
}

/* --- footer---- */

.footer-bottom p {
    font-size: 13px;
    color: #cacaca;
    margin-bottom: 0;
}
.section-space-small {
    padding: 80px 0;
}
.footer-title {
    color: #fff;
}

.footer-widget-2 ul.service-list li:hover {
    color: #fff;
    text-decoration: underline;
}

.footer-widget-2 ul.service-list li {
    position: relative;
    color: #cacaca;
    margin-bottom: 10px;
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.footer-widget-1 .footer-socials {
    display: inline-flex;
    gap: 10px;
}
.footer-widget-1 .footer-socials a {
    color: #cacaca !important;
    height: 36px;
    width: 36px;
    border: 1px solid #cacaca;
    font-size: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease;
}

.footer-widget-1 .footer-socials a:hover {
    background-color: #26468e;
    color: #cacaca !important;
}

.border-bottom-custom {
    border-bottom: 1px solid #737373;
}

.footer-icon {
    width: 30px;
    border-radius: 50px;
    height: 30px;
    font-size: 14px;
    color: #d0cfcf;
    background: #244590;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-foot p, .contact-foot a {
    color: #cacaca;
    font-size: 13px;
    margin-bottom: 0px;
    line-height: normal;
    text-decoration: none;
}
.contact-foot a:hover {
    text-decoration: underline !important;
    color: #fff !important;
}

.footer-main .footer-shape-1 {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
}
.footer-main .footer-shape-2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top right;
}
.footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 1;
}
.footer-bottom p a {
    color: #cacaca;
    text-decoration: none;
}
.footer-bottom p a:hover {
    text-decoration: underline !important;
    color: #fff !important;
}
.pl-50 {
    padding-inline-start: 50px;
}


.header-area{
    top: 0;
}

.cust-padding3{
    padding-top: 70px !important;
    margin-top: 94px !important;
}

.cursor-pointer{
    cursor: pointer;
}

.form-select {
    -webkit-appearance: none;
}
/* responsive */

@media(min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1350px !important;
    }
}

@media (max-width: 1199px) {
    h2 {
        font-size: 36px !important;
    }
    .main-menu ul li {
        margin-inline-end: 18px;
    }
    .sidebar__toggle button{
        background-color: transparent;
        border: none;
        font-size: 50px;
        font-weight: 700;
        padding: 0;
        color: #fff;
    }
    .btn-close:focus{
        box-shadow: none !important;
    }
    #header-sticky-active  .sidebar__toggle button{
        color: #000000;
    }
    .side-bar.btn-close {
        --bs-btn-close-bg: unset !important;
    }

    /* .modal-header .btn-close{
        --bs-btn-close-bg: auto !important;
    } */

    .offcanvas-header .btn-close, .offcanvas-header .btn-close:hover{
        font-size: 16px;
        background-color: #052D83;
        color: #fff !important;
        border-radius: 50%;
        opacity: 1 !important;
        padding: 10px !important;
    }
    .pl-50{
        padding-inline-start: 0px;
    }
}

@media (max-width: 991px) {
    .container, .container-md, .container-sm {
        max-width: 850px !important;
    }
    #header-sticky{
        padding: 14px 0;
    }
    #header-sticky-active{
        padding: 14px 0;
    }
    .main-menu ul li {
        display: block;
        padding: 14px 0;
        border-bottom: 1px solid #ddd;
        font-weight: 600 !important;
        color: #101010;
    }
    .main-menu ul li:last-child{
        border-bottom: none !important;
    }
    .mmenu-ul li{
        border-bottom: none !important;
        padding: 0 !important;
    }
    .main-menu ul li a{
        padding: 0;
       
    }
    .main-menu ul li > a {
        color: #101010;
        padding: 7px 0;
    }
    .sidebar__toggle .offcanvas{
        overflow-y: scroll;
    }
    .sidebar__toggle .offcanvas-body{
        overflow-y: visible;
    }
    .color-row-menu {
        padding: 16px 0 0 16px;
        padding-right: 0;
    }
    .color-row-menu h6 {
        font-size: 16px !important;
        color: #868686;
        font-weight: 700 !important;
    }

    .mbl-sub-list{
        display: flex;
        justify-content: space-between;
    }
    .offcanvas-header .btn-close i::before{
        vertical-align: 1px !important;
    }
    .pl-50 {
        padding-inline-start: 0px;
    }
    .socials-bucket, .common-cta_bucket .custom-cta_common{
        display: none;
    }

    .section-heading {
        font-size: 24px !important;
    }
    
    .footer-bottom p{
        text-align: center;
        padding-bottom: 4px;
    }
    .cust-padding, .ip-padding {
        padding: 70px 0px;
    }
    .abt-cta-inner h4 {
        font-size: 36px !important;
    }
    table {
        white-space: nowrap;
    }
    .right figure.image {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
      .project-slider-two-box figure{
        height: auto;
    }
    .careers-card {
        padding: 40px !important;
    }
}

@media (max-width: 767px) {
    .cust-padding {
        padding: 40px 0px;
    }
    .cust-padding2 {
        padding: 40px 0px 0px;
    }
    .primary-btn-1 {
        font-size: 16px !important;
    }
    .prods-img img {
        height: 100%;
    }
    .ip-padding{
        padding: 50px 0;
    }
    .ba-sec {
        flex-direction: column;
    }
    .anime-arrow img {
        transform: rotate(90deg);
        width: 80px;
    }
}

@media (max-width: 575px) {
    h5 {
        font-size: 18px !important;
    }
    .section-heading {
        font-size: 20px !important;
    }
    .main-btn {
        padding: 8px 18px;
        font-size: 16px;
    }
    .section-space-small {
        padding: 50px 0 !important;
    }
    .cust-padding {
        padding: 30px 0px;
    }
    .certi-padding {
        padding: 30px 0px 0px !important;
    }
    .light-bg {
        font-size: 18px;
    }
    .abt-cta-inner h4 {
        font-size: 24px !important;
    }
    .abt-cta {
            padding: 25px !important;
            border-radius: 10px !important;
    }
    table td {
        font-size: 16px !important;
    }
    .cta-cover {
        padding: 20px;
    }
    .product-title {
        font-size: 24px !important;
    }
    .product-description, .section-paragraph{
        font-size: 16px !important;
    }
    .section-sub_heading {
        font-size: 18px;
    }
    .product-specification .accordion-button {
        padding: 16px 22px;
        color: #101010 !important;
        font-size: 18px;
        line-height: 30px;
    }
    .icon-list li {
        font-size: 16px;
    }
    .footer-widget-2 ul.service-list li {
        font-size: 14px !important;
    }
    .footer-widget-1{
        font-size: 14px !important;
    }    
    .contact-foot p, .contact-foot a {
        font-size: 14px !important;
    }
    .footer-bottom p {
        font-size: 13px;
    }
    .scroll-to-top {      
        bottom: 90px !important;
    } 
}


/* --- */
@media only screen and (min-width: 1400px) {
.product-accordion{
    max-width: 68%;
    margin: 0 auto;
}
}
@media only screen and (min-width: 991px) {
    .w-lg-75 {
        width: 75%;
    }
    .w-lg-50 {
        width: 50%;
    }
}
@media only screen and (min-width: 767px) {
    .w-md-50 {
        width: 50%;
    }
}   